import React from 'react';

const IconPower = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
      <g data-name="Group 13605">
        <path
          d="M0 0h40v40H0z"
          data-name="Rectangle 3293"
          fill="#ab9766"
        />
        <path
          d="M11.327 14.859a1.521 1.521 0 0 1 .6-1.734 1.609 1.609 0 0 1 .932-.306 1.472 1.472 0 0 1 .9.308A1.578 1.578 0 0 0 12.24 12a1.558 1.558 0 0 0-.911.294 1.577 1.577 0 0 0 0 2.565zm2.639 6.434A17.2 17.2 0 0 1 19.346 24L24 20.681c-1.208-1.692-.875-2.876-2.075-4.558-.464-.652-.885-1.084-1.565-1.084a2.971 2.971 0 0 0-.573.075l.422.592a.33.33 0 0 1-.538.384l-.377-.528a.576.576 0 0 0-.469-.241 5.728 5.728 0 0 0-.978.2l.513.718a.33.33 0 1 1-.538.383L17.378 16a.575.575 0 0 0-.467-.241A5.419 5.419 0 0 0 15.9 16l.543.759a.33.33 0 0 1-.538.384l-2.2-3.077a.9.9 0 1 0-1.471 1.049l3.246 4.55c-.87-.322-2.217-.463-2.467.44-.149.538.282.966.95 1.19zM9 6h4v4.089a3.646 3.646 0 0 0-.758-.089A3.517 3.517 0 0 0 9 12.117V6zm2.562 15.972L11 22a11.02 11.02 0 1 1 10.744-8.658 3.3 3.3 0 0 0-1.385-.3 3.733 3.733 0 0 0-.615.054A8.995 8.995 0 1 0 11 20h.011a2.78 2.78 0 0 0 .551 1.972z"
          fill="#fff"
          transform="translate(8 7.76)"
        />
      </g>
    </svg>
  );
};

export default IconPower;
