import React from 'react';

const IconEnergy = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
      <g data-name="Group 13609">
        <path
          d="M0 0h40v40H0z"
          data-name="Rectangle 3297"
          fill="#ab9766"
        />
        <path
          d="m12.571 18.762 1.352-4.19h-4.5l6.9-7.333-1.44 4.19h3.972zM14.143 2a10.988 10.988 0 0 0-10.871 9.429H0l4.19 6.2 4.19-6.2H5.4a8.894 8.894 0 1 1 1.23 6.33l-1.257 1.86A10.995 10.995 0 1 0 14.143 2z"
          fill="#fff"
          transform="translate(6 7.42)"
        />
      </g>
    </svg>
  );
};

export default IconEnergy;
