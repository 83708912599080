import React, { useContext, useState, useEffect } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Swatch from './swatch';
import style from './viewer.mod.scss';
import { motion, AnimatePresence } from 'framer-motion';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpIcon from '@mui/icons-material/Help';

const isBrowser = () => typeof window !== 'undefined' && window;
const mql = isBrowser() ? window.matchMedia('(max-width: 1024px)') : false;
const isMobile = mql.matches;

const Viewer = ({
  modifiers = [],
  comms,
  integration,
  items,
  showArViewer,
}) => {
  // Set all variants from the items passed to the component
  const allVariants = items;

  const hasMissingSceneId = allVariants.some(variant => !variant.sceneId); // has any missing sceneIds?
  const hasCabinetColor = allVariants.some((variant) => variant.cabinetColor); // has any non-null colors?

  // Find all the shell/cabinet colors from the list of all variants
  const allCabinetsList = Object.values(
    allVariants.reduce(
      (acc, obj) => ({ ...acc, [obj.cabinetColor?.title]: obj.cabinetColor }),
      {}
    )
  );

  const allShellsList = Object.values(
    allVariants.reduce(
      (acc, obj) => ({ ...acc, [obj.shellColor.title]: obj.shellColor }),
      {}
    )
  );

  // Set the default variant by getting the first in the list
  const defaultVariant = allVariants[0];

  // State used to identify the current selected variant
  const [currentVariant, setCurrentVariant] = useState(defaultVariant);
  // Find the new variant

  const updateShell = (variant) => {
    const newVariant = allVariants.find(
      (item) =>
        item.shellColor.title === variant &&
        item?.cabinetColor?.title === currentVariant?.cabinetColor?.title
    );
    if (newVariant) {
      setCurrentVariant(newVariant);
    } else {
      alert('Sorry! That option is not currently available.');
    }
  };

  const updateCabinet = (variant) => {
    const newVariant = allVariants.find(
      (item) =>
        item?.cabinetColor?.title === variant &&
        item.shellColor.title === currentVariant.shellColor.title
    );
    if (newVariant) {
      setCurrentVariant(newVariant);
    } else {
      alert('Sorry! That option is not currently available.');
    }
  };

  // Custom states to control viewer
  const [plattarSrc, setPlattarSrc] = useState('');
  const [qrCodeSrc, setQrCodeSrc] = useState('');
  const [is3dViewerVisible, setIs3dViewerVisible] = useState(false);
  const [isArSelected, setIsArSelected] = useState(false);
  const [isQrCodeVisible, setIsQrCodeVisible] = useState(false);
  const [isCustomiseOpen, setIsCustomiseOpen] = useState(false);
  const [isPlattarConnected, setisPlattarConnected] = useState(false);
  const [shouldViewerAnimate, setShouldViewerAnimate] = useState(false);

  // State controlled modifiers
  isQrCodeVisible && modifiers.push(style['viewer--qr-code-viewer']);
  isCustomiseOpen && modifiers.push(style['viewer--customise-open']);
  shouldViewerAnimate && modifiers.push(style['viewer--animate']);

  // Initiate plattar scripts
  const viewerInit = () => {
    comms.init('plattar-frame');
    comms.send('initpreview', {});
    comms.on('previewready', () => {
      loadScene();
      setisPlattarConnected(true);
      loadProduct();
    });
  };

  // Set the scene ID from the current variant
  const loadScene = () => {
    comms.send('loadscene', {
      sceneId: `${currentVariant.sceneId}`,
    });
  };

  // Load the current variant properties
  const loadProduct = () => {
    setIsArSelected(false);
    setIsQrCodeVisible(false);
    loadCabinet();
    loadShell();
  };

  // Set the cabinet ID from the current variant
  const loadCabinet = () => {
    comms.send('loadproduct', {
      productId: `${currentVariant.cabinetProductId}`,
      variationId: `${currentVariant.cabinetVariationId}`,
    });
  };

  // Set the shell ID from the current variant
  const loadShell = () => {
    comms.send('loadproduct', {
      productId: `${currentVariant.shellProductId}`,
      variationId: `${currentVariant.shellVariationId}`,
    });
  };

  useEffect(() => {
    if (isQrCodeVisible && isPlattarConnected) {
      setIs3dViewerVisible(true);
    }
    if (isQrCodeVisible && !isPlattarConnected) {
      viewerInit();
    }
    setIsArSelected(false);
    setIsQrCodeVisible(false);
    if (isPlattarConnected) {
      loadScene();
      loadProduct();
    }
  }, [currentVariant?.sceneId]);

  useEffect(() => {
    if (isQrCodeVisible && isPlattarConnected) {
      setIs3dViewerVisible(true);
    }
    if (isQrCodeVisible && !isPlattarConnected) {
      viewerInit();
    }
    setIsArSelected(false);
    setIsQrCodeVisible(false);
    if (isPlattarConnected) {
      loadCabinet();
    }
  }, [currentVariant?.cabinetVariationId]);

  useEffect(() => {
    if (isQrCodeVisible && isPlattarConnected) {
      setIs3dViewerVisible(true);
    }
    if (isQrCodeVisible && !isPlattarConnected) {
      viewerInit();
    }
    setIsArSelected(false);
    setIsQrCodeVisible(false);
    if (isPlattarConnected) {
      loadShell();
    }
  }, [currentVariant?.shellVariationId]);

  const close3dViewer = () => {
    setIs3dViewerVisible(false);
    setShouldViewerAnimate(false);
    setIsArSelected(false);
    setIsQrCodeVisible(false);
  };

  const open3dViewer = () => {
    if (!isPlattarConnected) {
      viewerInit();
    }
    setIs3dViewerVisible(true);
    setShouldViewerAnimate(true);
    setIsArSelected(false);
    setIsQrCodeVisible(false);
  };

  // //---------- END VIEWER CONFIG ----------//

  // //---------- MOBILE VIEWER CONFIG ----------//

  const toggleIsCustomiseOpen = () =>
    isCustomiseOpen ? setIsCustomiseOpen(false) : setIsCustomiseOpen(true);

  const variants = {
    collapsed: {
      height: '0',
      opacity: 0,
      transition: {
        opacity: {
          duration: 0.2,
          ease: 'linear',
        },
        height: {
          ease: 'easeOut',
        },
      },
    },
    expanded: {
      height: 'auto',
      opacity: 1,
      transition: {
        opacity: {
          ease: 'linear',
        },
        height: {
          ease: 'easeOut',
        },
      },
    },
  };

  // //---------- END MOBILE VIEWER CONFIG ----------//

  // // const openModal = () => {
  // //   gtmPush({
  // //     event: "view-ar-help-video",
  // //     label: `AR help button clicked`
  // //   })
  // //   setModalOpen(true)
  // // }
  // const closeModal = () => setModalOpen(false);

  const initAr = () => {
    // gtmPush({
    //   event: 'initiate-view-in-home',
    //   label: `View in home button clicked`,
    // });
    setShouldViewerAnimate(true);
    setIsArSelected(false);

    // if the device is not AR compatible then QR code should display. Else AR should run.
    integration.init(
      `${currentVariant.arProductId}`,
      `${currentVariant.arVariationId}`,
      () => {
        setQrCodeSrc(integration.qrUrl);

        if (!integration.canAugment) {
          setIsQrCodeVisible(true);
        } else {
          integration.startAugment();
        }
      }
    );
    setIsArSelected(true);
    // if (!hasViewedHelpVideo) {
    //   localStorage.setItem("hasViewedHelperVideo", JSON.stringify(true))
    //   setHasViewedHelpVideo(true)
    // }
  };

  // const handleHelpVideo = () => {
  //   const ls = getLocalStorage("hasViewedHelperVideo")
  //   if (ls) {
  //     setHasViewedHelpVideo(true)
  //   } else {
  //     setHasViewedHelpVideo(false)
  //     openModal()
  //   }
  // }

  const handleCloseButton = () => {
    if (isQrCodeVisible) {
      if (!isPlattarConnected) {
        viewerInit();
      }
      setIs3dViewerVisible(true);
      setIsQrCodeVisible(false);
      setIsArSelected(false);
    } else close3dViewer();
  };

  return (
    <>
      {/* <Modal
        video={video}
        globalState={isModalOpen}
        onChange={closeModal}
        ariaLabel={`Open modal and play video: ${video.title}`}
        gtm={{
          event: `video-engagement`,
          label: `Video Modal Click`,
          value: `${video.title}`
        }}
      /> */}
      {items.length > 0 && (
        <div className={[style.viewer, ...modifiers].join(' ')}>
          {showArViewer && !hasMissingSceneId && (
            <div className={style.viewer__intro}>
              <div className={style.viewer__buttons}>
                {/* <Button
                type="button"
                children="Click to view in 3D"
                white
                secondary
                fullWidth
                disabled={is3dViewerVisible}
                icon={<IconViewer />}
                onClick={() => {
                  open3dViewer();
                  // gtmPush({
                  //   event: 'initiate-360-viewer',
                  //   label: `Click to view in 3D button clicked`,
                  // });
                }}
              /> */}

                <ViewerButton
                  text={'Click to view in 3D'}
                  icon={<IconViewer />}
                  onClick={() => {
                    open3dViewer();
                    // gtmPush({
                    //   event: 'initiate-360-viewer',
                    //   label: `Click to view in 3D button clicked`,
                    // });
                  }}
                />
              </div>
            </div>
          )}

          {/* CONTAINER START */}
          <motion.div
            className={style.viewer__container}
            animate={
              shouldViewerAnimate
                ? { backgroundColor: 'rgba(244, 244, 244, 1)' }
                : { backgroundColor: 'rgba(244, 244, 244, 0)' }
            }
          >
            <div className={style.viewer__product}>
              <motion.iframe
                id="plattar-frame"
                className={style.viewer__iframe}
                src="https://app.plattar.com/webgleditor/preview/index.html?allowAR=false"
                width="100%"
                height="100%"
                frameBorder="0"
                animate={is3dViewerVisible ? { opacity: 1 } : { opacity: 0 }}
              />
              <div
                id="plattar-frame-ar"
                className={style.viewer__iframe_ar}
                width="100%"
                height="100%"
                frameBorder="0"
                allow="camera; vr; autoplay"
                allowFullScreen
                src={plattarSrc}
              >
                <div className={style.viewer__fallback}>
                  <div className={style.viewer__home}>
                    <h3>{'View in home'}</h3>
                    <p>
                      {
                        'Open the camera app on your phone or tablet and scan this code'
                      }
                    </p>
                  </div>
                  <div className={style.viewer__qr}>
                    <img src={qrCodeSrc} />
                  </div>
                </div>
              </div>

              <div className={style.viewer__close}>
                <button onClick={handleCloseButton}>
                  <CancelIcon />
                </button>
              </div>
              <div className={style.viewer__render}>
                <AnimatePresence>
                  <motion.div
                    className={style['viewer__render-image']}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { delay: 0.6 } }}
                    exit={{ opacity: 0 }}
                    // key={`viewer-render-image-${currentRender?.id}`}
                  >
                    <GatsbyImage
                      className={style['viewer__render-image-gatsby']}
                      objectFit="contain"
                      // image={currentRender?.image?.gatsbyImageData}
                      image={currentVariant?.render?.gatsbyImageData}
                    />
                  </motion.div>
                </AnimatePresence>
              </div>
              <div className={style.viewer__ar}>
                {/* <Button
                  type="button"
                  children="View in home"
                  secondary
                  onClick={initAr}
                  disabled={!isMobile && isArSelected}
                  // gaTarget={'initiate-view-in-home'}
                /> */}

                <ViewerButton
                  text={'View in home'}
                  onClick={initAr}
                  large
                  dark
                />
              </div>
              {/* <div className={style.viewer__help}>
                <button
                // onClick={openModal}
                >
                  <HelpIcon />
                </button>
              </div> */}
            </div>

            {/* CUSTOMISE TAB START */}
            <div
              className={style.viewer__customise}
              onClick={toggleIsCustomiseOpen}
            >
              <div className={style.viewer__tab}>
                <div className={style.viewer__circle}></div>
              </div>
              <motion.div
                className={style.viewer__arrow}
                animate={isCustomiseOpen ? 'open' : 'collapsed'}
                initial={'collapsed'}
                variants={{
                  open: { rotate: 180 },
                  collapsed: { rotate: 0 },
                }}
              >
                <KeyboardArrowUpIcon />
              </motion.div>
              <h2>{'Customise'}</h2>
            </div>
            {/* CUSTOMISE TAB END
            CONTROLS START
            This needs to be animated in on mobile devices when isCustomiseOpen is true after isArSelected is true  */}
            <motion.div
              className={style.viewer__controls}
              layoutTransition
              useInvertedScale
              key={`viewer-controls-motion}`}
              animate={
                shouldViewerAnimate && isMobile && !isCustomiseOpen
                  ? 'collapsed'
                  : 'expanded'
              }
              initial={shouldViewerAnimate ? 'collapsed' : 'expanded'}
              variants={variants}
            >
              {/* OPTIONS START */}
              <div className={style.viewer__options}>
                <div className={style.viewer__swatches}>
                  <div>
                    <div className={style.viewer__wrap}>
                      <h3>{hasCabinetColor ? 'Shell Colours' : 'Colours'}</h3>
                      <div className={style.viewer__list}>
                        {allShellsList.map((shell) => {
                          return (
                            <div
                              key={`swatch-${shell.title}`}
                              className={style.viewer__item}
                              onClick={() => {
                                updateShell(shell.title);
                              }}
                            >
                              <Swatch
                                swatch={shell.swatch}
                                name={shell.title}
                                value={shell.title}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  {hasCabinetColor && (
                    <div>
                      <div className={style.viewer__wrap}>
                        <h3>{'Cabinet Colours'}</h3>
                        <div className={style.viewer__list}>
                          {allCabinetsList.map((cabinet) => {
                            if (cabinet) {
                              return (
                                <div
                                  key={`swatch-${cabinet?.title}`}
                                  className={style.viewer__item}
                                  onClick={() => {
                                    updateCabinet(cabinet?.title);
                                  }}
                                >
                                  <Swatch
                                    swatch={cabinet?.swatch}
                                    name={cabinet?.title}
                                    value={cabinet?.title}
                                  />
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* OPTIONS END */}
            </motion.div>
            {/* CONTROLS END */}
          </motion.div>
          {/* CONTAINER END */}
          <p className={style.viewer__disclaimer}>
            {
              '*Disclaimer: The 3D and AR models may show additional extras. Actual colours may differ from renders.'
            }
          </p>
        </div>
      )}
    </>
  );
};

export default Viewer;

const IconViewer = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <path
      d="M24 10.12a.88.88 0 00-.49 1.7c2.87.84 4.72 2.11 4.72 3.24 0 .97-1.33 2.04-3.55 2.86-1.1.41-2.36.74-3.73.98.13-1.26.2-2.56.2-3.9 0-3.56-.48-6.93-1.35-9.6l.7.26a.88.88 0 10.62-1.65l-2.22-.84a8.58 8.58 0 00-1.16-1.76C17.18.77 16.26 0 15.04 0c-1.2 0-2.12.77-2.69 1.41-.69.8-1.3 1.9-1.82 3.3-.5 1.37-.9 2.95-1.16 4.67-1.72.27-3.3.66-4.66 1.17-1.4.51-2.5 1.12-3.3 1.82-.64.56-1.41 1.48-1.41 2.7 0 1.2.77 2.13 1.41 2.69.8.69 1.9 1.3 3.3 1.82a27.8 27.8 0 007.4 1.48l-.47.45a.88.88 0 001.22 1.28l1.71-1.63a40.27 40.27 0 004.31-.2 21.84 21.84 0 01-.97 3.72c-.82 2.22-1.9 3.55-2.86 3.55-.8 0-1.7-.93-2.45-2.57a.88.88 0 10-1.6.75c1.37 2.97 2.96 3.59 4.05 3.59 1.2 0 2.13-.77 2.7-1.41.69-.8 1.3-1.9 1.82-3.3.49-1.34.88-2.88 1.14-4.56 1.69-.27 3.24-.66 4.58-1.15 1.4-.52 2.5-1.13 3.3-1.82.64-.56 1.41-1.49 1.41-2.7 0-2.05-2.13-3.8-6-4.94zm-9 9.28h-.37l-1.52-1.76a.88.88 0 10-1.34 1.16l.43.5c-2.6-.2-4.97-.67-6.88-1.38-2.22-.82-3.55-1.89-3.55-2.86s1.33-2.04 3.55-2.86c1.13-.41 2.41-.75 3.81-1a38.25 38.25 0 00-.17 4.5c0 .48.4.86.88.86h.01c.5 0 .88-.4.87-.9a38.1 38.1 0 01.22-4.7 36.22 36.22 0 015.5-.2.88.88 0 00.1-1.76 38.22 38.22 0 00-5.34.15 22 22 0 01.99-3.83c.82-2.22 1.9-3.55 2.86-3.55.74 0 1.54.77 2.24 2.13l-.92 2.12a.88.88 0 101.63.7l.21-.5A30.53 30.53 0 0119.38 15c0 1.44-.08 2.84-.23 4.16-1.32.16-2.72.24-4.15.24z"
      fill="currentColor"
      fill-rule="nonzero"
    />
  </svg>
);

const ViewerButton = ({ text, onClick, icon, large = false, dark = false }) => {
  const classes = [style.viewer_button];
  icon && classes.push(style.viewer_button_icon);
  large && classes.push(style.viewer_button_large);
  dark && classes.push(style.viewer_button_dark);

  return (
    <div className={[...classes].join(' ')} onClick={onClick}>
      {icon && <span>{icon}</span>}
      <span className={style.viewer_button__text}>{text}</span>
    </div>
  );
};
