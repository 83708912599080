import React from 'react';

const IconDesign = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
      <g data-name="Group 13604">
        <path
          d="M0 0h40v40H0z"
          data-name="Rectangle 3292"
          fill='#ab9766'
        />
        <path
          d="M5.171 20.688 0 22l1.281-5.2zM2.578 15.5l3.889 3.889L22 3.889 18.111 0 2.578 15.5zm12.938-2.551-1.3 1.3.649.649 1.3-1.3 1.3 1.3-1.945 1.945.648.648 1.945-1.945 1.3 1.27-2.593 2.593-3.879-3.88-1.3 1.3L16.815 22 22 16.814l-5.173-5.173-1.312 1.31zM6.452 9.046l-3.859-3.86 2.593-2.593 1.255 1.284L4.5 5.822l.648.648 1.941-1.945 1.3 1.3-1.3 1.3.648.648 1.3-1.3 1.313-1.31L5.186 0 0 5.186l5.154 5.155 1.3-1.3z"
          fill="#fff"
          transform="translate(9 8.76)"
        />
      </g>
    </svg>
  );
};

export default IconDesign;
