import React from 'react';

const IconWater = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
      <g data-name="Group 13607">
        <path
          d="M0 0h40v40H0z"
          data-name="Rectangle 3295"
          fill="#ab9766"
        />
        <path
          d="M15.1 13c1.354 3.876.474 7.194-2.167 8.549 3.859.2 6.28-4.164 2.167-8.549zm-2.433-6.977c2.952 5.618 6.5 7.278 6.5 11.391a6.5 6.5 0 0 1-13 0c0-4.113 3.547-5.771 6.5-11.391zm0-6.023C10.139 9.7 4 11.037 4 17.415a8.667 8.667 0 0 0 17.333 0c0-6.378-6.139-7.715-8.666-17.415z"
          fill="#fff"
          transform="translate(7 6.76)"
        />
      </g>
    </svg>
  );
};

export default IconWater;
