import React from 'react';
import Container from '../Layout/SharedStyles/Container';
import style from './product-features.mod.scss';
import IconSwirlPrimary from '../Layout/Icons/IconSwirlPrimary';

const ProductFeatures = ({ heading, text }) => {
  return (
    <Container width="medium" pt={3}>
      <div className={style.features}>
        <h2>{heading}</h2>
        <p>{text}</p>
      </div>
    </Container>
  );
};

export default ProductFeatures;
