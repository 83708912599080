import React, { useState } from 'react';
import { Collapse } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import style from './accordion.mod.scss';
import cn from 'classnames';

const Accordion = ({ icon, label, items, isDefaultOpen }) => {
  const [open, setOpen] = useState(isDefaultOpen);

  const handleOnClick = () => {
    setOpen(!open);
  };

  const classes = cn(style.accordion, {
    [style[`accordion--open`]]: open,
  });

  return (
    <div className={classes} onClick={handleOnClick}>
      <div className={style.accordion__header}>
        <span className={style.accordion__icon}>{icon}</span>
        <div className={style.accordion__content}>
          <span className={style.accordion__label}>{label}</span>
          <span className={style.accordion__dropdownIcon}>
            <KeyboardArrowDownIcon />
          </span>
        </div>
      </div>
      <Collapse in={open}>
        <div className={style.accordion__body}>
          {items.map((item) => (
            <div className={style.accordion__detail}>
              <span>{item.title}</span>
              <span>{item.value}</span>
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
};

export default Accordion;