import React from 'react';

const IconLighting = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
      <g data-name="Group 13608">
        <path
          d="M0 0h40v40H0z"
          data-name="Rectangle 3296"
          fill="#ab9766"
        />
        <path
          d="M20.167 7.3c0 4.511-4.062 7.562-4.062 11.121h-2.159c0-2.252 1.08-4.145 2.132-5.972C17.066 10.727 18 9.1 18 7.3a5.121 5.121 0 0 0-5.421-5.129A5.117 5.117 0 0 0 7.167 7.3c0 1.809.934 3.432 1.922 5.149 1.052 1.828 2.13 3.72 2.131 5.972H9.063C9.063 14.857 5 11.805 5 7.3 5 2.59 8.79 0 12.579 0s7.588 2.593 7.588 7.3zm-4.334 12.742a.542.542 0 0 1-.542.542H9.875a.542.542 0 1 1 0-1.083h5.417a.542.542 0 0 1 .541.541zm0 2.167a.542.542 0 0 1-.542.542H9.875a.542.542 0 1 1 0-1.083h5.417a.542.542 0 0 1 .541.54zm-1.843 3.422a1.088 1.088 0 0 1-.816.369h-1.183a1.086 1.086 0 0 1-.815-.369L9.6 23.833h5.958z"
          fill="#fff"
          transform="translate(8 6.76)"
        />
      </g>
    </svg>
  );
};

export default IconLighting;
