import React from 'react';
import style from './product-nav.mod.scss';
import Scroll from 'react-scroll';

const ScrollLink = Scroll.Link;

const ProductNav = () => {
  const links = [
    {
      text: 'Overview',
      link: 'product-overview',
    },
    {
      text: 'Colours',
      link: 'product-colors',
    },
    {
      text: 'Design',
      link: 'product-design',
    },
    {
      text: 'Specifications',
      link: 'product-specs',
    },
    {
      text: 'Compare',
      link: 'product-compare',
    },
  ];

  return (
    <div className={style.nav}>
      <div className={style.nav__wrap}>
        <ul>
          {links.map(({ text, link }) => {
            return <LinkItem text={text} link={link} />;
          })}
        </ul>
      </div>
    </div>
  );
};

export default ProductNav;

const LinkItem = ({ text, link }) => {
  return (
    <li>
      <ScrollLink to={link} spy={true} smooth={true} duration={500}>
        {text}
      </ScrollLink>
    </li>
  );
};
