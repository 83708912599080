import React from 'react';

const IconExperience = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
      <g data-name="Group 13606">
        <path
          d="M0 0h40v40H0z"
          data-name="Rectangle 3294"
          fill="#ab9766"
        />
        <path
          d="M12.917 0 1 6.5v13.142L12.917 26l11.917-6.358V6.57L12.917 0zm8.569 7.2-8.564 4.57-8.528-4.652 8.517-4.646zM3.167 8.916l8.667 4.727v9.324l-8.667-4.624V8.916zM14 22.967V13.65l8.667-4.625v9.317z"
          fill="#fff"
          transform="translate(7 6.76)"
        />
      </g>
    </svg>
  );
};

export default IconExperience;
