import React from 'react';
import Container from '../Layout/SharedStyles/Container';
import FeaturedCards from './FeaturedCards';
import PathwaysCard from './PathwaysCard';

const FeaturedCardsComponent = ({ pathways }) => {
  return (
    <Container bg={'light-gold'}>
      <FeaturedCards>
        {pathways.map(({ heading, subHeading, text, image, link }) => (
          <PathwaysCard
            heading={heading}
            subheading={subHeading}
            text={text}
            image={image}
            link={link}
          />
        ))}
      </FeaturedCards>
    </Container>
  );
};
export default FeaturedCardsComponent;
