import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Container from '../Layout/SharedStyles/Container';
import Button from '../Button/Button';
import style from './product-overview.mod.scss';
import ContentWithMedia from '../Content/ContentWithMedia';

const ProductOverview = ({ 
  ...props
 }) => {

  const contactPage = useStaticQuery(graphql`
    {
      datoCmsContactPage {
        originalId
      }
    }
  `);
  return (
    <Container pt={2} pb={2} width={'none'} gutters={false} bg={'gold'}>
      <ContentWithMedia {...props} />
    </Container>
  );
};

export default ProductOverview;
