import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { PageWrapper } from '../components/Layout/PageWrapper';
import { PageIntro } from '../components/Layout/PageIntro/PageIntro';
import ModuleArea from '../components/ModularContent/ModuleArea';
import ProductIntro from '../components/Product/ProductIntro';
import ProductColors from '../components/Product/ProductColors';
import ProductCompare from '../components/Product/ProductCompare';
import ProductNav from '../components/Product/ProductNav';
import ProductOverview from '../components/Product/ProductOverview';
import ProductSpecs from '../components/Product/ProductSpecs';
import ProductFeatures from '../components/Product/ProductFeatures';
import FeaturedCardsComponent from '../components/Cards/FeaturedCardsComponent';

const ProductPages = ({
  data: {
    allDatoCmsProductSpecItem,
    datoCmsProduct: {
      title,
      collection,
      featureImage,
      featuresHeading,
      featuresText,
      description,
      productFeatureList,
      modularContent,
      specWidth,
      specLength,
      specPdfLink,
      specLinkText,
      specItems,
      specsImage,
      specText,
      media,
      buttons,
      overviewHeading,
      overviewContent,
      comparableProducts,
      comparableSpecs,
      compareImage,
      productVariants,
      showArViewer,
      topImage,
      id,
      metaTags,
      pathways,
    },
    homeCrumb,
  },
  pageContext,
}) => {
  const [comms, setComms] = useState(null);
  const [integration, setIntegration] = useState(null);
  useEffect(() => {
    setComms(new window.PlattarViewerCommunicator());
    setIntegration(new window.PlattarIntegration());
    return () => {
      setComms(null);
      setIntegration(null);
    };
  }, []);

  const breadcrumbs = [
    { title: homeCrumb.title, id: homeCrumb.id },
    { title: pageContext.collection.title, id: pageContext.collection.id },
    { title: title, id: id },
  ];

  return (
    <PageWrapper pageData={pageContext} metaTags={metaTags}>
      <PageIntro title={title} breadcrumbs={breadcrumbs} button />
      <ProductIntro
        image={topImage}
        text={description}
        list={productFeatureList}
      />
      <ProductNav />
      <div id={'product-overview'}>
        <ProductOverview
          imageSide={'Right'}
          heading={overviewHeading}
          media={media}
          content={overviewContent}
          buttons={buttons}
        />
      </div>
      {productVariants.length > 0 && (
        <ProductColors
          variants={productVariants}
          comms={comms}
          integration={integration}
          showArViewer={showArViewer}
        />
      )}
      <div id={'product-design'}>
        <ProductFeatures heading={featuresHeading} text={featuresText} />
        {modularContent?.length > 0 && <ModuleArea blocks={modularContent} />}
      </div>
      <ProductSpecs
        image={specsImage}
        length={specLength}
        width={specWidth}
        pdfLink={specPdfLink}
        linkText={specLinkText}
        items={specItems}
        text={specText}
      />
      <ProductCompare
        product={{
          title: title,
          productFeatureList: productFeatureList,
          featureImage: compareImage,
        }}
        comparableProducts={comparableProducts}
        specList={comparableSpecs}
      />
      <FeaturedCardsComponent pathways={pathways}></FeaturedCardsComponent>
    </PageWrapper>
  );
};

export default ProductPages;

export const query = graphql`
  query ProductPages($locale: String!, $id: String!) {
    allDatoCmsProductSpecItem(filter: { locale: { eq: $locale } }) {
      nodes {
        position
        title
        titleTranslation
      }
    }
    homeCrumb: datoCmsHomepage(locale: { eq: $locale }) {
      locale
      title
      id: originalId
    }
    datoCmsProduct(locale: { eq: $locale }, originalId: { eq: $id }) {
      locale
      metaTags {
        title
        description
        image {
          url
        }
      }
      title
      id: originalId
      collection {
        id: originalId
        title
      }
      productFeatureList {
        specReference {
          title
          titleTranslation
        }
        value
      }
      comparableSpecs {
        title
        titleTranslation
      }
      comparableProducts {
        title
        id: originalId
        featureImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "300"
              fit: "fill"
              fill: "solid"
              w: "400"
              q: 60
              fillColor: "00ffffff"
              auto: "format"
            }
          )
        }
        productFeatureList {
          value
          specReference {
            title
            titleTranslation
          }
        }
      }
      specWidth
      specText
      specPdfLink
      specLinkText
      specLength
      specItems {
        details {
          title
          value
        }
        heading
        icon
      }
      specsImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { fm: "webp", h: "540", w: "540", q: 60 }
        )
      }
      topImage: specsImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { fm: "webp", w: "550", q: 60 }
        )
      }
      featuresHeading
      featuresText
      media {
        ... on DatoCmsMediaImage {
          id
          __typename
          image {
            gatsbyImageData
          }
        }
        ... on DatoCmsMediaVideo {
          id
          __typename
          video: videoUrl {
            provider
            providerUid
            title
            url
          }
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: {
                fm: "webp"
                h: "500"
                fit: "crop"
                w: "720"
                q: 60
              }
            )
          }
        }
      }
      overviewHeading
      overviewContent
      buttons {
        id
        page {
          ... on DatoCmsHomepage {
            id: originalId
          }
          ... on DatoCmsOtherPage {
            id: originalId
          }
          ... on DatoCmsCollection {
            id: originalId
          }
          ... on DatoCmsDealerPage {
            id: originalId
          }
          ... on DatoCmsContactPage {
            id: originalId
          }
        }
        text
      }
      description
      featureImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { fm: "webp", h: "450", w: "680", q: 60 }
        )
      }
      compareImage: featureImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: {
            h: "300"
            fit: "fill"
            fill: "solid"
            w: "400"
            q: 60
            fillColor: "00ffffff"
            auto: "format"
          }
        )
      }
      showArViewer
      productVariants {
        internalTitle
        sceneId
        cabinetProductId
        cabinetVariationId
        shellProductId
        shellVariationId
        arProductId
        arVariationId
        cabinetColor {
          title
          swatch {
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: {
                h: "485"
                fit: "cover"
                w: "820"
                q: 60
                auto: "format"
              }
            )
            alt
          }
        }
        shellColor {
          title
          swatch {
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: {
                h: "485"
                fit: "cover"
                w: "820"
                q: 60
                auto: "format"
              }
            )
            alt
          }
        }
        render {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: { fit: "cover", w: "1200", q: 60 }
          )
          alt
        }
      }
      pathways {
        heading
        image {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: { fm: "webp", h: "270", fit: "crop", w: "440", q: 60 }
          )
        }
        link {
          ...Link
        }
        subHeading
        text
      }
      modularContent {
        ... on DatoCmsContentBlock {
          ...Content
        }
        ... on DatoCmsSliderStandardWidthBlock {
          ...SliderStandardWidth
        }
        ... on DatoCmsLeadBlock {
          ...LeadBlock
        }
        ... on DatoCmsImageBlock {
          ...Image
        }
        ... on DatoCmsContentWithMediaBlock {
          ...ContentWithMedia
        }
      }
    }
  }
`;
